<template>
  <div v-loading="isLoading" class="other">
    <ExporterHeaderTop
      :options="navigationExporterList"
      @goToPage="goToPage"
      @logout="logout"
    />
    <ExporterHeaderBottom :terminals="exporterTerminals" />
    <router-view />
  </div>
</template>

<script>
import {
  AUTH_LOGOUT,
  FETCH_TERMINALS,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
} from '@/store/actions'
import { FETCH_CULTURES } from '@/views/control/store/actions'
import {
  FETCH_USER_EXPORTERS,
  GET_IS_LOADING_USER_EXPORTERS,
  SET_USER_EXPORTERS,
} from '@/views/exporter/store/actions'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { navigationExporterList } from '@/constants/navigaiton'
import ExporterHeaderBottom from '@/components/layouts/exporter/exporter-header/exporter-header-bottom/ExporterHeaderBottom'
import ExporterHeaderTop from '@/components/layouts/exporter/exporter-header/exporter-header-top/ExporterHeaderTop'

export default {
  name: 'Exporter',
  components: { ExporterHeaderTop, ExporterHeaderBottom },
  data() {
    return {
      navigationExporterList,
    }
  },
  computed: {
    ...mapGetters({
      currentTerminal: GET_TERMINAL_CURRENT_ID,
      isLoading: GET_IS_LOADING_USER_EXPORTERS,
      exporterTerminals: GET_TERMINAL_LIST,
    }),
  },
  watch: {
    currentTerminal() {
      this.init()
    },
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    this.clearUserExporters([])
  },
  methods: {
    ...mapActions({
      logout: AUTH_LOGOUT,
      fetchUserExporters: FETCH_USER_EXPORTERS,
      fetchCultures: FETCH_CULTURES,
      fetchTerminals: FETCH_TERMINALS,
    }),
    ...mapMutations({ clearUserExporters: SET_USER_EXPORTERS }),
    init() {
      this.fetchUserExporters()
      this.fetchCultures()
      this.fetchTerminals()
    },
    goToPage(routeName) {
      this.$router.push({ name: routeName })
    },
  },
}
</script>
